/*
Borrowed from https://dev.to/kirteshbansal/bouncing-dots-loader-in-react-4jng
*/

.bouncing-loader {
  display: flex;
  justify-content: center;
  margin-top: 10px;
  margin-bottom: 10px
}

.bouncing-loader > div {
  width: 10px;
  height: 10px;
  margin: 2px 4px;
  border-radius: 50%;
  background-color: #a3a1a1;
  opacity: 1;
  animation: bouncing-loader 0.6s infinite alternate;
}

@keyframes bouncing-loader {
  to {
    opacity: 0.1;
    transform: translateY(-8px);
  }
}

.bouncing-loader > div:nth-child(2) {
  animation-delay: 0.2s;
}

.bouncing-loader > div:nth-child(3) {
  animation-delay: 0.4s;
}
