.stockMediaContainer {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.stockMediaImageContainer {
  height:  calc(100% - 90px);
  overflow: auto;
  text-align: center;
}

.stockMediaAttribution {
  font-size: 6px;
}