$maxMessageWidth: 75%;
$spacingForAvatar: 50px;
.message {
  margin-bottom: 10px;
  border-radius: 20px;
  width: 100%;

  .displayMessage {
    white-space: pre-wrap;
    padding: 10px;
    overflow-wrap: break-word;
  }

  &.alki {
    background: rgba(255, 255, 255, 1);
    border-radius: 2px 20px 20px 20px;
    max-width: $maxMessageWidth;
    align-self: flex-start;
    text-align: left;
    width: initial;
    margin-left: $spacingForAvatar;
  }

  &.wrapNewlines {
    white-space: pre-wrap;
  }


  &.user {
    background: rgba(91, 79, 182, 1);
    border-radius: 20px 20px 2px 20px;
    max-width: $maxMessageWidth;
    align-self: flex-end;
    text-align: left;
    width: initial;
    color: rgba(255, 255, 255, 1);

    a {
      color: rgba(255, 255, 255, 1);
    }
  }

  &.hidden {
    display: none;
  }
}

.message-dots {
  display: flex;
  justify-content: center;
  margin-top: 3px;
  margin-bottom: 3px;
}

.message-dots > div {
  width: 5px;
  height: 5px;
  margin: 1px 2px;
  border-radius: 50%;
  background-color: #a3a1a1;
  opacity: 1;
  animation: message-dots 0.6s infinite alternate;
}

@keyframes message-dots {
  to {
    opacity: 0.6;
  }
}

.message-dots > div:nth-child(2) {
  animation-delay: 0.2s;
}

.message-dots > div:nth-child(3) {
  animation-delay: 0.4s;
}
