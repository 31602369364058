// inspired from https://codepen.io/nelledejones/pen/gOOPWrK

.scrollHintAnimation {
  animation: bounceHorizontal 2s ease 6s 1;
}

@keyframes bounceHorizontal {
  0%, 20%, 50%, 80%, 100% {
    transform: translateX(0);
  }
  40% {
    transform: translateX(-15%);
  }
  60% {
    transform: translateX(-7.5%);
  }
}

.flipAndRotate {
 animation: rotation 2s linear infinite;
}

@keyframes rotation {
  100% {
    transform: scaleX(-1) rotate(-360deg);
  }
}
