.assistantChat {
  .chatTextField {
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
    border-radius: 2px;
    transform: scale(1);
    animation: pulse 2s infinite;
    animation-delay: 15s;
  }

  @keyframes pulse {
    0% {
      box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
    }

    70% {
      box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
    }

    100% {
      box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    }
  }
}