.confirmFacebook {
  .profile-container {
    text-align: center;
    padding-top: 20px;
  }

  .profile-image {
    width: 92px;
    height: 92px;
    display: block;
    margin: 0 auto 8px auto;
    background: none;
  }

  p {
    word-break: break-word;
    margin: auto;
    font-size: 16px;
  }

}
