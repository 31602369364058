.postIdea {
  .postIdeaContentWrapper {
    width: fit-content;

    .postIdeaContent {
      border-radius: 10px;
      border: 1px solid #707070;
      display: flex;
      overflow: hidden;
      z-index: 0;
    }
  }

  .bulletPoint {
    margin-left: 15px;
    display: list-item;
    list-style-type: disc;
  }

  .stockTerm {
    font-size: smaller;
  }

  .configuration {
    font-weight: 100;
    text-align: left;
    font-size: x-small;
    overflow-x: scroll;
  }

  .options {
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
  }
}
