@import url('https://fonts.googleapis.com/css?family=Montserrat:regular,bold,extrabold,black');

html, body{
    background: linear-gradient(
        238deg,
        #e0546b 0.63%,
        #af4c80 47.83%,
        #6e3f9a 93.1%
      );
  }
