.externalMediaCellContainer {
  position: relative;
  display: inline-block;
  width: 100px;
  height: 100px;
  margin: 1.75px 3.5px;
  cursor: pointer;
  overflow: hidden;

  img, video {
    object-fit: cover;
    width: 100%;
    height: 108%;
    pointer-events: none;
  }

  .cellSpinner {
    position: absolute;
    left: 53px;
    top: 45px;
    z-index: 1;

    .md-circular-progress-path {
      stroke: #0f0;
    }
  }

  .overlay {
    background-color: rgba(0, 0, 0, 0.35);
    position: absolute;
    width: 100%;
    height: 100%;
  }
}