$previewWidth: 108px;
$previewHeight: 192px;

.previewThumbnail {
  width: $previewWidth;
  height: $previewHeight;
  position: relative;
  display: block;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  align-items: center;

  &.square{
    width: $previewWidth;
    height: $previewWidth;
  }

  .previewPlaceholder {
    width: $previewWidth;
    height: $previewHeight;
  }

  .designCanvasWrapper {
    width: 100%;
    height: 100%;
  }

  .designIframe {
    border: none;
    width: 100%;
    height: 100%;
    border-radius: 5px;
  }

  .clickTarget {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .safeAreaIgReels, .igReelsUi {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
}


