$previewWidth: 306px;
$previewHeight: 544px;


.preview {
  width: $previewWidth;
  height: $previewHeight;
  position: relative;
  display: block;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  align-items: center;

  &.holiday-layout-preview {
    @media (max-width: 420px) and (min-width: 391px) {
      width: $previewWidth * .9;
      height: $previewHeight * .9;
    }
    @media (max-width: 390px) {
      width: $previewWidth * .8;
      height: $previewHeight * .8;
    }
  }

  &.editor-preview {
    z-index: 1;
  }

  &.square {
    width: $previewWidth;
    height: $previewWidth;

    &.editor-preview {
      @media (min-width: $previewWidth * 2) {
        width: $previewWidth * 1.5;
        height: $previewWidth * 1.5;
      }
    }

    &.holiday-layout-preview {
      @media (max-width: 420px) and (min-width: 391px) {
        width: $previewWidth * .9;
        height: $previewWidth * .9;
      }
      @media (max-width: 390px) {
        width: $previewWidth * .8;
        height: $previewWidth * .8;
      }
    }
  }

  .previewPlaceholder {
    width: $previewWidth;
    height: $previewHeight;
  }

  .designCanvasWrapper {
    width: 100%;
    height: 100%;
  }

  .designIframe {
    border: none;
    width: 100%;
    height: 100%;
    border-radius: 5px;
  }

  .playButtonOverlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none; // allow clicks to go through to the design
  }

  .safeAreaIgReels, .igReelsUi {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
}

